import { AlertColor } from '@mui/material';
import { useContext, createContext } from 'react';
import { QlikAPI } from './qlikAPI_typedef';

// TODO: Specify the data structure type held in AuthContext
export const AuthContext = createContext(null);
export function useAuthContext() {
  return useContext(AuthContext);
}

export const QlikConfigContext = createContext<{
  spaces: QlikAPI.Space[] | null;
  qlikConfig: {
    spaceName: string;
  };
  refreshSpacesList: () => void;
  seenEnigmaError: (error: any | Error, severity: AlertColor) => void;
  shouldRefreshData: () => void; // Trigger an update
  refreshDataVal: number;        // Used by useEffect to invoke refresh events
}>(null as any);
export function useQlikConfigContext() {
  return useContext(QlikConfigContext);
}


export const ErrorContext = createContext<{
  reportError: (error: any | Error, severity: AlertColor) => void;
}>(null as any);

export function useErrorContext() {
  return useContext(ErrorContext);
}
