import { Auth } from 'aws-amplify';
import { caseInsensitiveIncludes } from './misc';
import './qlikAPI_typedef';
import { QlikAPI } from './qlikAPI_typedef';
import { QlikHelperError } from './QlikHelperError';
import {
  getApps,
  getBookmarks,
  getQixAPI,
  getQlikOverview,
  getSignedQLikJWT,
  getSpaces,
  isLoggedIn,
  loginToQlikWithToken,
} from './qlikLib';

export enum QlikHelperErrorTypes {
  noSpaceFound = 'noSpaceFound',
}

export async function tryLogin() {
  try {
    const loggedIn = await isLoggedIn();

    // If the user is not logged in, try to establish a session
    if (loggedIn === false) {
      try {
        let cognitoUser = await Auth.currentSession();

        // If not, generate a token and use it to login to QLik
        const token = await getSignedQLikJWT(cognitoUser);
        const authResponse = await loginToQlikWithToken(token);

        if (authResponse.ok === false) {
          throw new Error('Failed to login to QLik with JWT: ' + token);
        }
      } catch (e) {
        throw new Error('Error when attemping to log into QLik: ' + e);
      }
    }
  } catch (e) {
    console.error('[qlikError.js]', e);
    return;
  }
}

/**
 * Get the i-th space in a tenant that the user has access to
 * @param {number} index The index of a space
 * @returns {Promise<string>} A space id
 */
export async function getSpaceId(index: number): Promise<string> {
  try {
    const response = await getSpaces();

    if (response.length == 0) throw new Error('No spaces found');

    return response[index].id;
  } catch (e) {
    throw new Error('Could not fetch space Id. Error: ' + e);
  }
}

/**
 * @deprecated
 *
 * Find an app in a space by its name
 * @param {string} spaceId The space to search
 * @param {string} name The name of an app
 * @returns {Promise<string>} An app id
 */
export async function getAppId(spaceId: string, name: string): Promise<string> {
  try {
    const response = await getApps(spaceId);
    name = name.trim(); // Clean the input

    if (response.length == 0) throw new Error('No apps found');

    const app = response.find((app) => app.name.includes(name));
    if (!app) throw new Error('app was not found');
    return app.id;
  } catch (e) {
    throw new Error('Could not fetch app Id. Error: ' + e);
  }
}

/**
 * @deprecated
 *
 * Find a sheet in an app by its bookmark name
 * @param {string} appId A valid Qlik app id
 * @param {string} name The name of a bookmark
 * @returns  {Promise<string>} A sheet id
 */
export async function getSheetId(appId: string, name: string): Promise<string> {
  const bookmarks = await getBookmarks(appId);
  name = name.trim(); // Clean the input

  if (bookmarks.length != 0) {
    return (bookmarks.find((sheet) => sheet.title.trim().includes(name)) as any)
      .sheetId;
  }

  throw new Error('Could not find sheet Id');
}

/**
 * Find a space by name.
 * @param {string} spaceName The name of a space.
 * @returns {Promise<QlikAPI_Space>} The space if found. Otherwise undefined.
 */
export async function findSpaceByName(
  spaceName: string,
  spaces?: QlikAPI.Space[]
): Promise<QlikAPI.Space> {
  const obj = spaces ?? (await getQlikOverview());

  let result = obj.find((space) =>
    caseInsensitiveIncludes(space.name, spaceName)
  );
  if (result == undefined)
    throw new QlikHelperError(
      `No space with the name '${spaceName}' was found.`,
      QlikHelperErrorTypes.noSpaceFound
    );
  return result;
}

/**
 * Find a space by index.
 * @returns {Promise<QlikAPI_Space>} The space if found. Otherwise undefined.
 */
export async function findSpaceByIndex(index: number): Promise<QlikAPI.Space> {
  const obj = await getQlikOverview();
  if (index < 0 && obj.length <= index)
    throw new Error(`Index ${index} is out of bounds.`);
  return obj[index];
}

/**
 * Find an app by name in a space.
 * @param {QlikAPI_Space} space
 * @param {string} appName
 * @returns {QlikAPI_App} The app if found. Otherwise undefined.
 */
export function space_findAppByName(
  space: QlikAPI.Space,
  appName: string
): QlikAPI.App {
  appName = appName.trim();
  let result = space.apps.find((app) =>
    caseInsensitiveIncludes(app.name, appName)
  );

  if (result == undefined)
    throw new Error(
      `No app with the name '${appName}' was found in the space ${space.name}.`
    );
  return result;
}

/**
 * Find a sheet by name in an app.
 * @param {QlikAPI_App} app The app to search.
 * @param {string} sheetName The sheet name to find.
 * @returns {QlikAPI_Sheet} The sheet if found. Otherwise undefined.
 */
export function app_findSheetByName(
  app: QlikAPI.App,
  sheetName: string
): QlikAPI.Sheet {
  sheetName = sheetName.trim();
  let result = app.sheets.find((sheet) =>
    caseInsensitiveIncludes(sheet.name, sheetName)
  );
  if (result == undefined)
    throw new Error(
      `No sheet with the name '${sheetName}' was found in the app ${app.name}.`
    );
  return result;
}

/**
 * Find an object by name in a sheet.
 * @param {QlikAPI_App} app The parent app of the sheet.
 * @param {QlikAPI_Sheet} sheet The sheet to search.
 * @param {string} objectTitle The object title to find.
 * @returns {Promise<string>} The object id if found. Otherwise undefined.
 */
export async function sheet_findObjectIdByTitle(
  app: QlikAPI.App,
  sheet: QlikAPI.Sheet,
  objectTitle: string
): Promise<string> {
  const api = await getQixAPI(app.id);

  for (let i = 0; i < sheet.objects.length; i++) {
    // Get the object reference
    let obj = await api.getObject(sheet.objects[i]);

    // Get the object properties
    let objProperties = await obj.getProperties();
    let title = objProperties.title;

    // Skip objects with no title
    if (title == '') continue;

    // A title can be a string or an expression
    if (title.qStringExpression != undefined)
      title = title.qStringExpression.qExpr;
    if (caseInsensitiveIncludes(title, objectTitle))
      return objProperties.qInfo.qId;
  }

  throw new Error(
    `No object with the title '${objectTitle}' was found in the sheet ${sheet.name}.`
  );
}

/**
 * Find an object by label in a sheet.
 * @param {QlikAPI_App} app The parent app of the sheet.
 * @param {QlikAPI_Sheet} sheet The sheet to search.
 * @param {string} label The object expression to find.
 * @returns {Promise<string>} The object id if found. Otherwise undefined.
 */
export async function sheet_findObjectIdByLabel(
  app: QlikAPI.App,
  sheet: QlikAPI.Sheet,
  label: string
): Promise<string> {
  const api = await getQixAPI(app.id);

  for (let i = 0; i < sheet.objects.length; i++) {
    // Get the object reference
    let obj = await api.getObject(sheet.objects[i]);

    // Get the object properties
    let objProperties = await obj.getProperties();
    if (objProperties.qHyperCubeDef && objProperties.qHyperCubeDef.qMeasures) {
      let qMeasures = objProperties.qHyperCubeDef.qMeasures;
      for (let j = 0; j < qMeasures.length; j++) {
        let qDef = qMeasures[j].qDef.qLabel;
        if (!qDef) {
          qDef = qMeasures[j].qDef.qLabelExpression;
        }
        if (caseInsensitiveIncludes(qDef, label))
          return objProperties.qInfo.qId;
      }
    }
  }

  throw new Error(
    `No object with the expression '${label}' was found in the sheet ${sheet.name}.`
  );
}

/**
 * Find an object by label in a sheet.
 * @param {QlikAPI_App} app The parent app of the sheet.
 * @param {QlikAPI_Sheet} sheet The sheet to search.
 * @param {string} def The object expression to find.
 * @returns {Promise<string>} The object id if found. Otherwise undefined.
 */
export async function sheet_findObjectIdByDef(
  app: QlikAPI.App,
  sheet: QlikAPI.Sheet,
  def: string
): Promise<string> {
  const api = await getQixAPI(app.id);

  for (let i = 0; i < sheet.objects.length; i++) {
    // Get the object reference
    let obj = await api.getObject(sheet.objects[i]);

    // Get the object properties
    let objProperties = await obj.getProperties();
    if (objProperties.qHyperCubeDef && objProperties.qHyperCubeDef.qMeasures) {
      let qMeasures = objProperties.qHyperCubeDef.qMeasures;
      for (let j = 0; j < qMeasures.length; j++) {
        let qDef = qMeasures[j].qDef.qDef;
        if (!qDef) {
          throw new Error(`!qDef is undefined`);
        }
        if (caseInsensitiveIncludes(qDef, def)) return objProperties.qInfo.qId;
      }
    }
  }
  throw new Error(
    `No object with the expression '${def}' was found in the sheet ${sheet.name}.`
  );
}

export interface SelectionObjectLayout extends EngineAPI.IGenericBaseLayout {
  qSelectionObject: {
    qSelections: {
      qField: string;
      qSelected: string;
      qSelectedCount: number;
    }[];
  };
}

/**
 * Gets a list of all the currently selected fields in the Qlik app.
 * Works by creating a session object that asks for `qSelectionObjectDef`
 *
 * https://help.qlik.com/en-US/sense-developer/February2023/Subsystems/EngineAPI/Content/Sense_EngineAPI/DiscoveringAndAnalysing/MakeSelections/get-current-selections.htm
 * @returns {string[]} A list of all the currently selected fields
 */
export async function getCurrentSelectedFields(enigmaRef: EngineAPI.IApp) {
  let obj = await enigmaRef.createSessionObject({
    qInfo: {
      qId: '',
      qType: 'SessionLists',
    },
    qSelectionObjectDef: {},
  });

  // Case the object to any so that the next lines are simpler to write
  let layout: any = await obj.getLayout();

  // Check if there are missing properties
  if (
    layout.qSelectionObject === undefined ||
    layout.qSelectionObject.qSelections === undefined
  ) {
    console.log(layout);
    throw new Error(
      'Properties of session object created in getCurrentSelectedFields do not have the required properties `qSelectionsObject` or `.qSelectionsObject.qSelections`'
    );
  }

  // There are no missing properties
  // Traverse the object to get the fields
  let selections = (layout as SelectionObjectLayout).qSelectionObject
    ?.qSelections;

  // Return the field names only
  return selections.map((selection) => selection.qField);
}
