// import "qlik-engineapi"
/// <reference types="qlik-engineapi" />
export namespace QlikAPI {
  export interface EnigmaSession extends Omit<enigmaJS.ISession, 'open'> {
    open(): Promise<EngineAPI.IGlobal>;
  }

  export interface SheetObjectPositions {
    name: string; // The id of the object.
    type: string; // The type of the object.
    row: number;
    rowspan: number;
    col: number;
    colspan: number;
    bounds: {
      height: number;
      width: number;
      x: number;
      y: number;
    };
  }

  export interface Sheet {
    name: string; // The name of the sheet.
    id: string; // The Qlik id of the sheet.
    cells: SheetObjectPositions; // The position of objects in the sheet.
    objects: string[]; // A list of all object ids in the sheet.
  }

  export interface App {
    name: string; // The name of the app.
    id: string; // The Qlik id of the app.
    sheets: Sheet[]; // The sheets in the app.
  }

  export interface Space {
    name: string; // The name of the space.
    id: string; // The Qlik id of the space.
    apps: App[]; // The apps in the space.
  }
}
