import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import './App.css';
import ClientLoaderAnimation from './components/loaders/ClientLoaderAnimation';
import AuthenticatedRoute from './components/Routes/AuthenticatedRoute';
import UnauthenticatedRoute from './components/Routes/UnauthenticatedRoute';
import { useAuthContext } from './lib/contextLib';
import {
  findSpaceByIndex, tryLogin
} from './lib/qlikHelper';
import Login from './pages/Login/Login';
import { AuthStates } from './types';


async function getCurrentOrg() {
  // Function is only used during development
  try {
    return 'demo';
    const space = await findSpaceByIndex(0); // A hack when the userInfo API was not available
    console.log('Fetched space successfully');
    return space.name; // Assumes that Qlik space names are an exact match for client customer codes
  } catch (e) {
    console.log('Failed to fetch first space');
  }

  return null;
}

function App() {
  const { authState, setAuthState } = useAuthContext();
  const [currentOrg, setCurrentOrg] = useState(null);
  const ClientLoader = React.lazy(() =>
    import(/* webpackChunkName: "companyroute" */ './clients/ClientLoader')
  );

  // Throw unhandled errors so they bubble to higher error boundaries
  const [unhandledError, setUnhandledError] = useState(null);
  if (unhandledError) {
    throw unhandledError;
  }

  useEffect(() => {
    async function onPageLoad() {
      try {
        await Auth.currentSession();

        // A user is logged into Cognito and to QLik
        setAuthState(AuthStates.signedIn);
      } catch (e) {
        console.error('[App.js] onLoad: ', e);
        setAuthState(AuthStates.notSignedIn);
        setCurrentOrg(null);
      }
    }

    async function afterSignIn() {
      await tryLogin();
      const currentOrganisation = await getCurrentOrg();
      setCurrentOrg(currentOrganisation);
    }

    if (authState == AuthStates.signedIn)
      afterSignIn().catch((error) => setUnhandledError(error));

    if (authState == AuthStates.loadingData) onPageLoad();
  }, [authState, setAuthState]);

  if (authState == AuthStates.loadingData) return <></>;

  return (
    <BrowserRouter>
      <Switch>
        <UnauthenticatedRoute path="/login" component={Login} />
        <AuthenticatedRoute path="/">
          <div className="App">
            {/* <Header /> */}
            {currentOrg != null && (
              <React.Suspense fallback={<ClientLoaderAnimation />}>
                <ClientLoader customerCode={currentOrg} />
              </React.Suspense>
            )}
            {currentOrg == null && <ClientLoaderAnimation />}
          </div>
        </AuthenticatedRoute>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
