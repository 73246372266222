//Config values for our react app

import apiConfig from './apiConfig.json'

export const config = {
  // Backend config
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_V4euy5REQ",
    APP_CLIENT_ID: "18i101bck2stdgt99bd0id4bb7",
    IDENTITY_POOL_ID: "us-east-1:78b18408-cc3a-40f7-bdcd-51631e7a2bcf"
  }
};

export function getApiConfigEndpoint(apiName){

    if(apiConfig[apiName] == null || apiConfig[apiName] == undefined) throw `Config Failed:\n There is no api endpoint for ${apiName}\n Config Json output\n${apiConfig}`

    return apiConfig[apiName]
}
