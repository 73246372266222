/**
 * Custom Error object thrown by methods in qlikHelper
 */
export class QlikHelperError extends Error {
  /**
   * 
   * @param message The error message
   * @param type The error type. A very short string representing the error and why it was raised.
   * This value is used for filtering QlikHelperErrors
   */
  constructor(message: string, public type: string) {
    super(message);
  }
}